import React, { useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames/bind"
import Img from "gatsby-image"
import SEO from "../../components/seo"

import wordmark_orange from '../../images/brand/wordmark_orange.svg';

import style from "./@retail.module.css"
import Anchors from "./anchors.js"
import Identity from "./identity.js"
import Clients from "./clients.js"
import Team from "./team.js"
import Contact from "./contact.js"
import Location from "./location.js"
import Footer from "./footer.js"
// import { Columns, Baseline } from "../../components/visual-aid.js"
// import client_mindlezz from "../../images/client/client-mindlezz.svg"

import {
    flashImage,
    customHookScrollEvent
    // pageSlowReveal,
    // highlightStoryLink,
    // highlightInterface,
    // customScrollEvent,
    // pullContent
} from "../../components/scroll-effects.js"

const cx = classNames.bind(style)

//


export default function Retail() {

    const data = useStaticQuery(
        graphql`
      query {

        #
        retail_landscape: file(relativePath: { eq: "work/retail/retail_landscape.jpg" }) {
          ...defaultImageTreatment
        }
        #
        retail_portrait: file(relativePath: { eq: "work/retail/retail_portrait.jpg" }) {
          ...defaultImageTreatment
        }




        #
        client_durian_01: file(relativePath: { eq: "work/retail/client_durian_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_durian_02: file(relativePath: { eq: "work/retail/client_durian_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_durian_03: file(relativePath: { eq: "work/retail/client_durian_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_durian_04: file(relativePath: { eq: "work/retail/client_durian_04.jpg" }) {
          ...defaultImageTreatment
        }
        #

        #
        client_interfaceflor_01: file(relativePath: { eq: "work/retail/client_interfaceflor_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_interfaceflor_02: file(relativePath: { eq: "work/retail/client_interfaceflor_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_interfaceflor_03: file(relativePath: { eq: "work/retail/client_interfaceflor_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_interfaceflor_04: file(relativePath: { eq: "work/retail/client_interfaceflor_04.jpg" }) {
          ...defaultImageTreatment
        }
        #

        #
        client_marutinandan_01: file(relativePath: { eq: "work/retail/client_marutinandan_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_marutinandan_02: file(relativePath: { eq: "work/retail/client_marutinandan_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_marutinandan_03: file(relativePath: { eq: "work/retail/client_marutinandan_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_marutinandan_04: file(relativePath: { eq: "work/retail/client_marutinandan_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_marutinandan_05: file(relativePath: { eq: "work/retail/client_marutinandan_05.jpg" }) {
          ...defaultImageTreatment
        }
        #

        #
        client_mohe_01: file(relativePath: { eq: "work/retail/client_mohe_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_mohe_02: file(relativePath: { eq: "work/retail/client_mohe_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_mohe_03: file(relativePath: { eq: "work/retail/client_mohe_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_mohe_04: file(relativePath: { eq: "work/retail/client_mohe_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_mohe_05: file(relativePath: { eq: "work/retail/client_mohe_05.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_mohe_06: file(relativePath: { eq: "work/retail/client_mohe_06.jpg" }) {
          ...defaultImageTreatment
        }
        #

        #
        client_oneplus_01: file(relativePath: { eq: "work/retail/client_oneplus_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_oneplus_02: file(relativePath: { eq: "work/retail/client_oneplus_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_oneplus_03: file(relativePath: { eq: "work/retail/client_oneplus_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_oneplus_04: file(relativePath: { eq: "work/retail/client_oneplus_04.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_oneplus_05: file(relativePath: { eq: "work/retail/client_oneplus_05.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_oneplus_06: file(relativePath: { eq: "work/retail/client_oneplus_06.jpg" }) {
            ...defaultImageTreatment
          }

        #
        client_oxford_01: file(relativePath: { eq: "work/retail/client_oxford_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_oxford_02: file(relativePath: { eq: "work/retail/client_oxford_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_oxford_03: file(relativePath: { eq: "work/retail/client_oxford_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_oxford_04: file(relativePath: { eq: "work/retail/client_oxford_04.jpg" }) {
            ...defaultImageTreatment
          }
          #

        #
        client_radhika_01: file(relativePath: { eq: "work/retail/client_radhika_01.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_radhika_02: file(relativePath: { eq: "work/retail/client_radhika_02.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_radhika_03: file(relativePath: { eq: "work/retail/client_radhika_03.jpg" }) {
          ...defaultImageTreatment
        }
        #
        client_radhika_04: file(relativePath: { eq: "work/retail/client_radhika_04.jpg" }) {
          ...defaultImageTreatment
        }
        #


      }
    `
    )

    useLayoutEffect(() => {
        // //
        flashImage("#client_durian_01")
        flashImage("#client_durian_02")
        flashImage("#client_durian_03")
        flashImage("#client_durian_04")
        //
        flashImage("#client_interfaceflor_01")
        flashImage("#client_interfaceflor_02")
        flashImage("#client_interfaceflor_03")
        flashImage("#client_interfaceflor_04")
        //
        flashImage("#client_marutinandan_01")
        flashImage("#client_marutinandan_02")
        flashImage("#client_marutinandan_03")
        flashImage("#client_marutinandan_04")
        flashImage("#client_marutinandan_05")
        //
        flashImage("#client_mohe_01")
        flashImage("#client_mohe_02")
        flashImage("#client_mohe_03")
        flashImage("#client_mohe_04")
        flashImage("#client_mohe_05")
        flashImage("#client_mohe_06")
        //
        //
        flashImage("#client_oneplus_01")
        flashImage("#client_oneplus_02")
        flashImage("#client_oneplus_03")
        flashImage("#client_oneplus_04")
        flashImage("#client_oneplus_05")
        flashImage("#client_oneplus_06")
        //
        flashImage("#client_oxford_01")
        flashImage("#client_oxford_02")
        flashImage("#client_oxford_03")
        flashImage("#client_oxford_04")
        //
        flashImage("#client_radhika_01")
        flashImage("#client_radhika_02")
        flashImage("#client_radhika_03")
        flashImage("#client_radhika_04")
        //
        //
        customHookScrollEvent("#tagline", "#tagline", "tagline_expanded", .9)
        customHookScrollEvent("#taglineTop", "#taglineTop", "taglineTop_expanded", .5)
    }, [])

    return (
        <>
            {/* <Columns />
            <Baseline /> */}

            <SEO
                title="Spaces For Life"
                keywords={[`UX`, `UX Strategy`, `Bangalore`, `UX Design`, `Consultant`]}
            />
            <div className={style.page}>
                <main className={style.main}>
                    <div className={style.aboveFold}>
                        <div className={style.topLine}></div>
                        <div className={style.topImage}>
                            <div className={cx(style.topImageAsset)}>
                                <div className={style.matte}></div>
                                <Img
                                    className={cx(style.image, style.bannerLandscape)}
                                    fluid={data.retail_landscape.childImageSharp.fluid}
                                    alt="retail_landscape.jpg"
                                />
                                <Img
                                    className={cx(style.image, style.bannerPortrait)}
                                    fluid={data.retail_portrait.childImageSharp.fluid}
                                    alt="retail_portrait.jpg"
                                />
                            </div>

                        </div>
                        <div className={style.topInfo}>
                            <img className={style.wordmark} src={wordmark_orange} alt="wordmark.svg" />
                            <div id="taglineTop" className={style.taglineTop}>
                                spaces for growth
                            </div>
                        </div>
                    </div>

                    <div id="about-work" className={style.aboutWork}>
                        <div className={style.header}>Retail Interiors</div>
                        <div className={style.aboutWorkText}>
                            A retail outlet is a place where one desires for the luxury, comfort and ease of spending. The visual display is most important aspect for all retail spaces, which need to be designed in a way to drive the visitor’s movement snugly. The designed space should make him empathize with the display and comfort. SAMYAK strives to create a space where the comfort meets with the elegance and necessity meets with the gratification so that the difference between buyer and seller defuses off to fabricate a homely environment. It is achieved through the use of variety of materials. Natural materials have their unique texture, colours and feel. These are used smartly to create a dynamic and compassionate ambience, most suitable for retail spaces.
                        </div>
                    </div>

                    <div id="work" className={style.work}>
                        <div className={style.header}>Work</div>

                        {/* Oneplus ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Soothing metaphors
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        The Mobile Experience Centre at Ludhiana was one of the important ventures by One Plus to capture the presence in surroundings. It was a prototype design as per One Plus standards. We took this project as Execution & Design detailing partners. There was value engineering involved at various levels. The same was well intermingled with design without compromising the established aesthetics. The colour scheme of the whole store is quite soothing. The brand value of the company is being reflected with excellent graphics and controlled texture of various materials. Though small but it was a milestone project that was achieved within the given limitations.
                                    </p>
                                    {/* <p>
                                        The design was done keeping the posh gentry in the locality. The dark rich background displays the products effectively in the focused light. The billing counter is made appealing in cart shape. A coffee & books corner inside the store is adding new value to it. Both existing large columns are treated in different ways accentuating the brand theme of Fresh and Organic. Graphics are chosen selectively to add the spice to the whole surrounding. A deliberate effort has been made to get the buyer indulge in the magic of euphoric environment of the store.
                                    </p> */}
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>One Plus India</div>
                                    <div className={style.projectLocation}>Ludhiana, Punjab</div>
                                    <div className={style.projectIndustry}>Communications</div>
                                    <div className={style.projectCompletion}>2020</div>
                                    <div className={style.projectSize}>2500</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oneplus_01" className={cx(style.image)}>
                                        <Img fluid={data.client_oneplus_01.childImageSharp.fluid}
                                            alt="client_oneplus_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oneplus_02" className={cx(style.image)}>
                                        <Img fluid={data.client_oneplus_02.childImageSharp.fluid}
                                            alt="client_oneplus_02.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oneplus_03" className={cx(style.image)}>
                                        <Img fluid={data.client_oneplus_03.childImageSharp.fluid}
                                            alt="client_oneplus_03.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oneplus_05" className={cx(style.image)}>
                                        <Img fluid={data.client_oneplus_05.childImageSharp.fluid}
                                            alt="client_oneplus_05.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oneplus_04" className={cx(style.image)}>
                                        <Img fluid={data.client_oneplus_04.childImageSharp.fluid}
                                            alt="client_oneplus_04.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oneplus_06" className={cx(style.image)}>
                                        <Img fluid={data.client_oneplus_06.childImageSharp.fluid}
                                            alt="client_oneplus_06.jpg"
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* Oneplus ****************************** */}

                        {/* Marutinandan ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Redefining Face Value
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        Shree Marutinandan restaurant is an Ahmedabad, Gujarat based chain of restaurants. They wished to open their first restaurant in Vadodara. We were given the freedom to experiment and redefine the design language for the chain. We designed the new restaurant with a contemporary look & feel. The approach was minimalistic yet bold and impressive. Low height RCC slab forced us to go for open ceiling concept. The dominated blue ceiling colour resembles the European outlets. Pinewood sections are used smartly in the ceiling as well as screening the south façade full height glasses. The main entry was changed with a welcoming granite planter. The reception & waiting area is accentuated with water fountain, whose soothing sound has a mesmerizing impact on dinners.
                                    </p>
                                    {/* <p>
                                        A LEED consultant was engaged during the design phase. It was an honest endeavour throughout the project to use recycled materials as much as possible. All the services – HVAC, Electricals, plumbing etc. were designed strictly following USGBC specified norms. LED light fixtures, automated light sensors, water-saving sanitary fixtures, low VOC materials etc. were a major focus. The materials, design & services all contributed coherently and led to receive LEED platinum for this small but state of the art office. The entire story was captured by print media in Construction world December 2012 issue.
                                    </p> */}
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>Jay Maruti Nandan</div>
                                    <div className={style.projectLocation}>Vadodara, Gujrat</div>
                                    <div className={style.projectIndustry}>Hospitality</div>
                                    <div className={style.projectCompletion}>2020</div>
                                    <div className={style.projectSize}>3000</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_marutinandan_01" className={cx(style.image)}>
                                        <Img fluid={data.client_marutinandan_01.childImageSharp.fluid}
                                            alt="client_marutinandan_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_marutinandan_02" className={cx(style.image)}>
                                        <Img fluid={data.client_marutinandan_02.childImageSharp.fluid}
                                            alt="client_marutinandan_02.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_marutinandan_03" className={cx(style.image)}>
                                        <Img fluid={data.client_marutinandan_03.childImageSharp.fluid}
                                            alt="client_marutinandan_03.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_marutinandan_04" className={cx(style.image)}>
                                        <Img fluid={data.client_marutinandan_04.childImageSharp.fluid}
                                            alt="client_marutinandan_04.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_marutinandan_05" className={cx(style.image)}>
                                        <Img fluid={data.client_marutinandan_05.childImageSharp.fluid}
                                            alt="client_marutinandan_05.jpg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Marutinandan ****************************** */}

                        {/* Mohe ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Passionate Shopping
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        Our client Mr Kulvinder approached us to design a new Supermarket store for Edifice Prateek, a well known posh society in sector-107, Noida. He was venturing into this new business of food retailing and asked us to design a completely new theme. Before starting the design process, a detailed case study was done with all prominent existing stores in the industry. The hard work by the design team resulted in a new food retail language - 'MoHE', Ministry of Home Essentials.
                                    </p>
                                    <p>
                                        The design was done keeping the posh gentry in the locality. The dark rich background displays the products effectively in the focused light. The billing counter is made appealing in cart shape. A coffee & books corner inside the store is adding new value to it. Both existing large columns are treated in different ways accentuating the brand theme of Fresh and Organic. Graphics are chosen selectively to add the spice to the whole surrounding. A deliberate effort has been made to get the buyer indulge in the magic of euphoric environment of the store.
                                    </p>
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>MoHE (BMR Realtors)</div>
                                    <div className={style.projectLocation}>Noida, UP, India.</div>
                                    <div className={style.projectIndustry}>Food & Retail</div>
                                    <div className={style.projectCompletion}>2021</div>
                                    <div className={style.projectSize}>2800</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_mohe_01" className={cx(style.image)}>
                                        <Img fluid={data.client_mohe_01.childImageSharp.fluid}
                                            alt="client_mohe_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_mohe_06" className={cx(style.image)}>
                                        <Img fluid={data.client_mohe_06.childImageSharp.fluid}
                                            alt="client_mohe_06.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_mohe_02" className={cx(style.image)}>
                                        <Img fluid={data.client_mohe_02.childImageSharp.fluid}
                                            alt="client_mohe_02.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_mohe_03" className={cx(style.image)}>
                                        <Img fluid={data.client_mohe_03.childImageSharp.fluid}
                                            alt="client_mohe_03.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_mohe_04" className={cx(style.image)}>
                                        <Img fluid={data.client_mohe_04.childImageSharp.fluid}
                                            alt="client_mohe_04.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_mohe_05" className={cx(style.image)}>
                                        <Img fluid={data.client_mohe_05.childImageSharp.fluid}
                                            alt="client_mohe_05.jpg"
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* Mohe ****************************** */}

                        {/* Radhika ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Showcasing Luxury
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        Radhika Fashions are 20-year-old venture dealing in direct retail of Indian Women’s Wear. They approached us to revamp their 2-decade old showroom on main Naraina Vihar road. They were also facing problems with wooden display units due to frequent termites attack. In the very small floor plate, we designed a luxurious show for our client.
                                    </p>
                                    <p>
                                        Use of materials ranged from - Corian, Charcoal Boards, Laminates, Glass to LED smart fixtures. Instead of a traditional wooden display, we proposed Metal & Glass display units. It increased the overall storage and display capacity, getting rid of termites and adding aesthetics to the place. The overall project is a short but sweet experience for our client.
                                    </p>
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>Radhika Fashions </div>
                                    <div className={style.projectLocation}>Naraina, New Delhi</div>
                                    <div className={style.projectIndustry}>Retail Showroom</div>
                                    <div className={style.projectCompletion}>2019</div>
                                    <div className={style.projectSize}>1000</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_radhika_01" className={cx(style.image)}>
                                        <Img fluid={data.client_radhika_01.childImageSharp.fluid}
                                            alt="client_radhika_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_radhika_03" className={cx(style.image)}>
                                        <Img fluid={data.client_radhika_03.childImageSharp.fluid}
                                            alt="client_radhika_03.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_radhika_02" className={cx(style.image)}>
                                        <Img fluid={data.client_radhika_02.childImageSharp.fluid}
                                            alt="client_radhika_02.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_radhika_04" className={cx(style.image)}>
                                        <Img fluid={data.client_radhika_04.childImageSharp.fluid}
                                            alt="client_radhika_04.jpg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Radhika ****************************** */}

                        {/* Interfaceflor ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Crafting Perfection
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        Interface is a US-based multinational global carpet manufacturing company. They set out to eliminate any negative impact on the environment with their Mission Zero® commitment. Their first Delhi office was proposed to become their first office to get LEED platinum certifications. Having a very small floor plate of mere 1200 sqft, it was a big challenge to create enough storages, staff seating, client meeting areas, waiting and above all central carpet display area. The design task was even tougher because the floor area of 1200 sqft was divided between 2 floors.
                                    </p>
                                    <p>
                                        A LEED consultant was engaged during the design phase. It was an honest endeavour throughout the project to use recycled materials as much as possible. All the services – HVAC, Electricals, plumbing etc. were designed strictly following USGBC specified norms. LED light fixtures, automated light sensors, water-saving sanitary fixtures, low VOC materials etc. were a major focus. The materials, design & services all contributed coherently and led to receive LEED platinum for this small but state of the art office. The entire story was captured by print media in Construction world December 2012 issue.
                                    </p>
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>InterfaceFlor India Pvt. Ltd</div>
                                    <div className={style.projectLocation}>New Delhi, India</div>
                                    <div className={style.projectIndustry}>Retail</div>
                                    <div className={style.projectCompletion}>2012</div>
                                    <div className={style.projectSize}>1250</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_interfaceflor_01" className={cx(style.image)}>
                                        <Img fluid={data.client_interfaceflor_01.childImageSharp.fluid}
                                            alt="client_interfaceflor_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_interfaceflor_02" className={cx(style.image)}>
                                        <Img fluid={data.client_interfaceflor_02.childImageSharp.fluid}
                                            alt="client_interfaceflor_02.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_interfaceflor_03" className={cx(style.image)}>
                                        <Img fluid={data.client_interfaceflor_03.childImageSharp.fluid}
                                            alt="client_interfaceflor_03.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_interfaceflor_04" className={cx(style.image)}>
                                        <Img fluid={data.client_interfaceflor_04.childImageSharp.fluid}
                                            alt="client_interfaceflor_04.jpg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Interfaceflor ****************************** */}

                        {/* Oxford ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Awaken identity
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        Oxford University Press India has been participating every year in international book fair being held at Pragati Maidan. OUP’s design head Mr Bindu asked us to design this stall in such a way that it should beat all previous designs that have been executed in past years. This dynamic challenge was accepted by Samyak. After 2-3 brainstorming sessions with the client, the design was finalized. The proposed design was pure contemporary look for a typical bookstall.</p>
                                    <p>
                                        The design advocated the long & glorious history of Oxford University Press which has now entered the new era. The shape and curves provided in the design which suggests this shift from ancient to modern adaptation is happening smoothly. The blue brand colour of Oxford logo used extensively in the design. OUP was happy to receive the final design. They further confirmed that the design was their best stall design ever for such exhibitions.
                                    </p>
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>Oxford University Press India</div>
                                    <div className={style.projectLocation}>Pragati Maidan, New Delhi, India</div>
                                    <div className={style.projectIndustry}>Publication</div>
                                    <div className={style.projectCompletion}>2015</div>
                                    <div className={style.projectSize}>1000</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oxford_01" className={cx(style.image)}>
                                        <Img fluid={data.client_oxford_01.childImageSharp.fluid}
                                            alt="client_oxford_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oxford_03" className={cx(style.image)}>
                                        <Img fluid={data.client_oxford_03.childImageSharp.fluid}
                                            alt="client_oxford_03.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oxford_02" className={cx(style.image)}>
                                        <Img fluid={data.client_oxford_02.childImageSharp.fluid}
                                            alt="client_oxford_02.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageHalf)}>
                                    <div className={style.matte}></div>
                                    <div id="client_oxford_04" className={cx(style.image)}>
                                        <Img fluid={data.client_oxford_04.childImageSharp.fluid}
                                            alt="client_oxford_04.jpg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Oxford ****************************** */}

                        {/* Durian ****************************** */}
                        <div className={style.project}>
                            <div className={style.projectDescription}>
                                <div className={style.projectDescriptionHighlight}>
                                    Exhibiting the Royal Way
                                </div>
                                <div className={style.projectDescriptionText}>
                                    <p>
                                        Durian is a well-known name in the current Indian furniture industry headquartered in Mumbai. For ACE TECH 2011, in Pragati Maidan Durian approached us to design their stall of 500 sqft area. The products for the display were – Plyboards, Veneers & Factory fitted doors. The design inputs from client stressed upon the fact that the display environment for the products shall have an ethnic and luxurious feel.  <br />
                                    </p>
                                    <p>
                                        Interestingly, the client suggested getting a soft-story on the origin of various veneers. This individual veneer story is placed in taper backlit box alongside veneer display. The overall surface designed to have a dark grey backdrop, upon which all the display products get highlighted. A self-supported metal framework held the spotlights. These lights were highlighting the objects amid the dark grey background. The design idea was fresh and it changed the perspective of their advertising and display forever.
                                    </p>
                                </div>
                                <div className={style.projectData}>
                                    <div className={style.projectName}>Durian Industries Ltd.</div>
                                    <div className={style.projectLocation}>Pragati Maidan, New Delhi, India</div>
                                    <div className={style.projectIndustry}>Furniture</div>
                                    <div className={style.projectCompletion}>2011</div>
                                    <div className={style.projectSize}>500</div>
                                </div>
                            </div>
                            <div className={style.projectImages}>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_durian_01" className={cx(style.image)}>
                                        <Img fluid={data.client_durian_01.childImageSharp.fluid}
                                            alt="client_durian_01.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_durian_02" className={cx(style.image)}>
                                        <Img fluid={data.client_durian_02.childImageSharp.fluid}
                                            alt="client_durian_02.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_durian_03" className={cx(style.image)}>
                                        <Img fluid={data.client_durian_03.childImageSharp.fluid}
                                            alt="client_durian_03.jpg"
                                        />
                                    </div>
                                </div>
                                <div className={cx(style.projectImageContainer, style.imageWide)}>
                                    <div className={style.matte}></div>
                                    <div id="client_durian_04" className={cx(style.image)}>
                                        <Img fluid={data.client_durian_04.childImageSharp.fluid}
                                            alt="client_durian_04.jpg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Durian ****************************** */}


                    </div>

                    <div id="team" >
                        < Team />
                    </div>

                    <div id="clients" >
                        < Clients />
                    </div>



                    <div id="location" >
                        < Location />
                    </div>

                    {/* <div id="contact" > */}
                    < Footer />
                    {/* </div> */}
                    <div id="contact" >
                        < Contact />
                    </div>

                    < Identity />

                    < Anchors />

                </main>
            </div>

        </>
    )
}
